import { cn } from '@/utils/classes';
import { type ReactNode } from 'react';
export interface TagProps {
  type: 'lightblue' | 'darkblue' | 'danger' | 'warning' | 'success' | 'info' | 'inactive';
  className?: string;
  id?: string;
  size?: 'sm' | 'md' | 'lg';
  children: ReactNode;
}
export const TypeMap: Record<TagProps['type'], string> = {
  lightblue: 'bg-blue-100 border-blue-200',
  darkblue: 'bg-blue-500 border-blue-600',
  danger: 'bg-[#F7C9CE] border-[#F7C9CE]',
  warning: 'bg-yellow-200 border-yellow-300',
  success: 'bg-[#E5F2E8] border-[#C9E4CF]',
  info: 'bg-blue-200 border-blue-300',
  inactive: 'bg-gray-200 border-gray-300'
};
export const SizeMap: Record<'sm' | 'md' | 'lg', string> = {
  sm: 'p-1 text-xs',
  md: 'p-2 text-sm',
  lg: 'p-3 text-md'
};
export function Tag({
  type = 'darkblue',
  size = 'md',
  id,
  className,
  children
}: TagProps) {
  return <span id={id} className={cn(TypeMap[type], SizeMap[size], 'inline-block rounded-md border font-semibold text-black', className)} data-sentry-component="Tag" data-sentry-source-file="Tag.tsx">
      {children}
    </span>;
}