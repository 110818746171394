'use client';

import { cn } from '@/utils/classes';
import { queryConfig, type QueryKeyBase } from '@/utils/react-query';
import { JSONSafeParse } from '@/utils/string';
import { type ServersideDataQueryOptions } from '@/utils/types';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { type ColumnDef, getCoreRowModel, type PaginationState, type SortingState, useReactTable } from '@tanstack/react-table';
import { useDebounce } from '@uidotdev/usehooks';
import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { type AddlParams, evalInitialTableParam, SearchInputMemo, TableEmpty, TableFooter, TableHeader, TableLoaderProgressBar, TableLoaderSkeleton, type TableParams, TableRows } from './Table.utils';

/* --------------------------------- premise -------------------------------- */
// Pass in a "queryApiFn" (aka a function that calls an API endpoint that supports the ServersideDataQueryOptions pattern)
// and allow this component to manage the state of the table (sorting, pagination, search) and refetch the data when needed.

/* ---------------------------- e.g. queryApiFn ---------------------------- */
// ./services/prescription.ts
// export const getPrescriptionTableData = ({ Page, ItemsPerPage, Search, SortBy, SortDesc }: ServersideDataQueryOptions) => {
//   const params = new URLSearchParams({ Page, ItemsPerPage, Search, SortBy, SortDesc } as unknown as Record<string, string>);
//   return podiAxios<{ items: PrescriptionTableItem[]; totalCount: number }>(`/api/Prescription?${params}`);
// };
//
//
// --GOOD (no arguments)
// queryApiFn === getPrescriptionTableData
//
// -- BAD (with arguments - this component will manage that state for you)
// queryApiFn !== getPrescriptionTableData({ Page: 1, ItemsPerPage: 10, Search: '', SortBy: 'lastName', SortDesc: true })

/* ---------------------------------- usage --------------------------------- */
// <TableServerside queryApiFn={getPrescriptionTableData} columns={columns} />

export interface TableServersideProps<T = any> {
  queryKeyBase: QueryKeyBase;
  queryApiFn: (options: ServersideDataQueryOptions) => Promise<{
    items: T[];
    totalCount: number;
  }>;
  title?: string;
  columns: ColumnDef<T>[];
  sorting?: SortingState;
  pagination?: PaginationState;
  search?: string;
  accessories?: JSX.Element;
  className?: string;
  rowClassesFn?: (item: T) => string;
  addlParams?: AddlParams;
  saveStateInUrl?: boolean;
  isSearchable?: boolean;
  disablePageSizeSelect?: boolean;
}
export function TableServerside<T>({
  title,
  queryKeyBase,
  queryApiFn,
  columns,
  sorting: _sorting = [],
  pagination: _pagination = {
    pageIndex: 0,
    pageSize: 10
  },
  search: _search = '',
  accessories,
  className = '',
  addlParams = {},
  rowClassesFn,
  saveStateInUrl = false,
  isSearchable = true,
  disablePageSizeSelect = false
}: TableServersideProps<T>) {
  const router = useRouter();
  const tableSearchParamsJSON = useSearchParams().get(queryKeyBase);
  const tableSearchParams = tableSearchParamsJSON ? JSONSafeParse<TableParams>(tableSearchParamsJSON) : {} as TableParams;
  const [sorting, setSorting] = useState<SortingState>(evalInitialTableParam(_sorting, saveStateInUrl && tableSearchParams?.SortBy ? [{
    id: tableSearchParams.SortBy,
    desc: tableSearchParams.SortDesc
  }] : undefined) as SortingState);
  const [pagination, setPagination] = useState<PaginationState>(evalInitialTableParam(_pagination, saveStateInUrl && tableSearchParams?.ItemsPerPage ? {
    pageIndex: tableSearchParams.Page,
    pageSize: tableSearchParams.ItemsPerPage
  } : undefined) as PaginationState);
  const [search, setSearch] = useState(evalInitialTableParam(_search, saveStateInUrl && tableSearchParams?.Search ? tableSearchParams.Search : undefined) as string);
  const debouncedSearch = useDebounce(search, 500);
  const tableParams: TableParams = {
    ItemsPerPage: pagination.pageSize,
    Page: pagination.pageIndex,
    Search: debouncedSearch,
    SortBy: sorting?.[0]?.id ?? null,
    SortDesc: sorting?.[0]?.desc ?? true
  };
  useEffect(() => {
    if (!saveStateInUrl) return;
    const existingParams = Object.fromEntries(new URLSearchParams(location.search));
    const newParams = {
      [queryKeyBase]: JSON.stringify(tableParams)
    };
    const params = new URLSearchParams({
      ...existingParams,
      ...newParams
    });
    router.push(`${window.location.pathname}?${params}`);
  }, [tableParams.ItemsPerPage, tableParams.Page, tableParams.Search, tableParams.SortBy, tableParams.SortDesc]);
  const {
    data,
    isFetching
  } = useQuery({
    ...queryConfig(queryKeyBase, queryApiFn, {
      ...tableParams,
      Page: tableParams.Page + 1,
      // "Page" property of "ServersideDataQueryOptions" is 1-based
      ...addlParams
    }),
    placeholderData: keepPreviousData // don't have 0 rows flash while changing pages/loading next page
  });
  const table = useReactTable({
    columns,
    data: data?.items ?? [],
    debugTable: true,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount: data?.totalCount,
    state: {
      sorting,
      pagination
    }
  });
  const hideHeader = !title && !accessories && !isSearchable;
  return <div className={cn('flex flex-col gap-4 overflow-hidden rounded-xl border', className)} data-sentry-component="TableServerside" data-sentry-source-file="TableServerside.tsx">
      {!hideHeader && <header className="flex flex-row items-center justify-between p-4">
          <span>{title && <h3 className="text-lg font-bold">{title}</h3>}</span>
          <div className="flex flex-row items-center justify-between gap-2">
            {!!accessories && accessories}
            {isSearchable && <SearchInputMemo query={search} onChange={value => setSearch(value)} />}
          </div>
        </header>}
      <table className="relative min-w-full divide-y divide-gray-200 overflow-hidden">
        <TableHeader headerGroups={table.getHeaderGroups()} data-sentry-element="TableHeader" data-sentry-source-file="TableServerside.tsx" />
        {isFetching && <TableLoaderProgressBar />}
        {!data ? <TableLoaderSkeleton pageSize={table.getState().pagination.pageSize} colSize={columns.length} /> : <tbody className="bg-white">
            {data.items.length === 0 && <TableEmpty colSize={columns.length} />}
            <TableRows rows={table.getRowModel().rows} rowClassesFn={rowClassesFn} />
          </tbody>}
      </table>
      <TableFooter table={table} disablePageSizeSelect={disablePageSizeSelect} data-sentry-element="TableFooter" data-sentry-source-file="TableServerside.tsx" />
    </div>;
}