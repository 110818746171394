import { cn } from '@/utils/classes';
import type { FC } from 'react';
type Size = 'sm' | 'md' | 'lg';
type Color = 'primary' | 'secondary' | 'destructive';
type Variant = 'default' | 'ghost' | 'outline';
export interface ButtonProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
  size?: Size;
  color?: Color;
  variant?: Variant;
  disabled?: boolean;
  loading?: boolean;
  type?: 'submit';
  id?: string;
}
const defaultButtonClasses: string = 'button shadow-button cursor-pointer mx-auto bg-podi-primary font-medium px-8 duration-300 drop-shadow-md hover:drop-shadow-none';
const SizeClassesMap: Record<Size, string> = {
  sm: 'w-auto mx-0 py-1 px-4 text-xs/5 rounded-md ',
  md: 'w-auto mx-0 py-2 px-8 text-sm/5 rounded-lg',
  lg: 'w-auto mx-0 py-3 px-12 text-lg/5 rounded-xl'
};
const TransparenTextColorsMap: Record<Color, string> = {
  primary: 'text-podi-primary',
  secondary: 'text-podi-secondary',
  destructive: 'text-red-500'
};
const ColorClassesMap: Record<Color, string> = {
  primary: 'bg-podi-primary border-podi-primary hover:bg-podi-primary-hover text-white',
  secondary: 'bg-podi-secondary border-podi-secondary hover:bg-podi-secondary-hover text-white',
  destructive: 'bg-red-500 border-red-500 hover:bg-[#31060A] hover:border-[#F7C9CE] text-white'
};
const DisabledClassesMap: Record<Color, string> = {
  primary: 'bg-podi-primary-disabled hover:bg-podi-primary-disabled',
  secondary: 'bg-podi-secondary-disabled hover:bg-podi-secondary-disabled',
  destructive: 'bg-red-500 border-red-500 hover:bg-[#31060A] hover:border-[#F7C9CE]'
};
const VariantClassesMap: Record<Variant, string> = {
  default: '',
  ghost: 'bg-transparent hover:bg-gray-100',
  outline: 'bg-transparent border hover:bg-gray-100'
};
const TRANSPARENT_VARIANTS = ['ghost', 'outline'];
export const getButtonClasses = ({
  size,
  color,
  variant,
  disabled
}: {
  size: Size;
  color: Color;
  variant: Variant;
  disabled: boolean;
}): string => {
  const sizeClasses = size ? SizeClassesMap[size] : '';
  const colorClasses = color ? ColorClassesMap[color] : '';
  const variantClasses = variant ? VariantClassesMap[variant] : '';
  const transparentAdjustmentClasses = TRANSPARENT_VARIANTS.includes(variant) ? TransparenTextColorsMap[color] : '';
  const disabledClasses = disabled ? DisabledClassesMap[color] + ' cursor-not-allowed disabled:shadow-none disabled:drop-shadow-none drop-shadow-none shadow-none' : '';
  return cn(defaultButtonClasses, sizeClasses, colorClasses, transparentAdjustmentClasses, variantClasses, disabledClasses);
};
export const Button: FC<ButtonProps> = ({
  size = 'md',
  color = 'primary',
  variant = 'default',
  className,
  disabled = false,
  loading,
  children,
  type,
  id,
  ...props
}) => {
  return <button id={id} disabled={loading || disabled} type={type} className={cn(getButtonClasses({
    size,
    color,
    variant,
    disabled
  }), className)} {...props} data-sentry-component="Button" data-sentry-source-file="Button.tsx">
      {children}
    </button>;
};