import { cn } from '@/utils/classes';
import { type ComponentPropsWithoutRef, useEffect, useRef, useState } from 'react';
import { type FieldError, type FieldErrorsImpl, type Merge } from 'react-hook-form';
import { FormLabel } from './FormLabel';
interface TextAreaProps extends Omit<ComponentPropsWithoutRef<'textarea'>, 'value'> {
  value: ComponentPropsWithoutRef<'textarea'>['value'] | null;
  label: string;
  error?: string;
  fieldError?: FieldError | Merge<FieldError, FieldErrorsImpl<{}>>;
  icon?: React.ReactNode;
  containerClassName?: string;
  inputClassNames?: string;
  labelClassNames?: string;
}
export function TextArea({
  value,
  id,
  label,
  error,
  fieldError,
  icon,
  rows,
  disabled = false,
  className,
  inputClassNames,
  ...props
}: TextAreaProps) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [textareaHeight] = useState<string>('auto');
  useEffect(() => {
    if (textareaRef.current) {
      // Set the textarea's height to "auto" to reset it
      textareaRef.current.style.height = 'auto';

      // Set the textarea's height based on its scrollHeight
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  });
  const autoClamp = rows ? `line-clamp-${rows}` : null;
  return <div className={cn('flex w-full items-center', className)} data-sentry-component="TextArea" data-sentry-source-file="TextArea.tsx">
      {icon}
      <div className={cn('w-full')}>
        {label && <FormLabel id={id}>{label}</FormLabel>}
        <textarea ref={textareaRef} id={id} rows={!rows ? 1 : rows} value={value ?? ''} disabled={disabled} {...props} style={{
        height: textareaHeight
      }} className={cn('block w-full resize appearance-none overflow-hidden rounded-md border border-gray-300 bg-white px-3 py-2 pb-3 text-sm text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-podi-primary sm:text-sm sm:leading-6', {
        'border-red-500 focus:border-red-500 focus:ring-red-500': error
      }, {
        'focus-ring-0 bg-gray-50 shadow-none ring-0': disabled
      }, autoClamp, inputClassNames)} />
        {error && <p className="!mt-0 !p-1 !text-xs !text-red-500">{error}</p>}
        {fieldError && <p className="!mt-0 !p-1 !text-xs !text-red-500">{fieldError.message}</p>}
      </div>
    </div>;
}