import { cn } from '@/utils/classes';
import { type FC } from 'react';
import { type ButtonProps, getButtonClasses } from './Button';
interface Props {
  size?: ButtonProps['size'];
  variant?: ButtonProps['variant'];
  color?: ButtonProps['color'];
  label?: string;
  id?: HTMLInputElement['id'];
  accept?: HTMLInputElement['accept'];
  onFileSelected?: (file: File) => void;
  disabled?: boolean;
  className?: string;
}
export const FileSelector: FC<Props> = ({
  id = 'file-selector',
  label = 'Add File',
  accept = '.csv',
  className,
  size = 'md',
  color = 'primary',
  variant = 'default',
  disabled = false,
  onFileSelected
}) => {
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files?.[0]) return;
    const _file = e.target.files[0];
    onFileSelected?.(_file);
  };
  return <>
      <label htmlFor={id} className={cn(getButtonClasses({
      size,
      color,
      variant,
      disabled
    }), 'px-4', className)}>
        {label}
      </label>
      <input id={id} name={id} type="file" className="hidden" disabled={disabled} onChange={handleFileChange} accept={accept} />
    </>;
};